export enum SubscriptionFrequency {
  MONTHLY = 'monthly',
  ANNUAL = 'annual'
}

export enum SubscriptionType {
  INDIVIDUAL = 'individual',
  SCHOOL = 'school'
}

export enum Plan {
  INDIVIDUAL_MONTHLY = 'individual-monthly',
  INDIVIDUAL_ANNUAL = 'individual-annual',
  SCHOOL_ANNUAL = 'school-annual'
}
